import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout pageInfo={{ pageName: "404" }}>
    <Seo
      title="404: Not found"
      keywords={[`crypto`, `FIBot`, `defi`, `FIBot`, `cryptoFIBot`]}
    />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
